.alcohol-notice-wrapper {
  // display: flex-direction
  background: black;
  color: white;
  .alcohol-notice {
    max-width: 500px;
    margin: 0 auto;
    padding: 0.5em;
  }
  h2 {
    font-weight: 600;
    text-align: center;
  }
  .source {
    text-align: right;
  }
}
