@import url("https://use.typekit.net/znc3jga.css");
// $signatureFont: "Lily Script One", serif;
$signatureFont: dunbar-low, serif;
$deco: benton-modern-display, serif;
$pink:  #cc0218;
$white: #fff;
$black: #000;
$background-color: #ffe5cc;
$purpleRadio: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);

.akashisushi {
  font-family: $signatureFont;
  background-color: $background-color;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23f2f2f2' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
  .cart.minimized {

    // background: rgba(237,237,237, 0.8);
    background-color: $background-color;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23f2f2f2' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
    // border-bottom: 2px solid #fc4550;
  }

  a.checkout-button.button {
    background: $pink;
  }

  .category__header {

    /* background: rgba(237,237,237, 0.4); */
  }
  .category-name .text {
    font-family: $deco;
    font-size: 1.5em;
    background: none;
    color: black;
  }
  .category-name .text::before {
    content: "☙"
  }

  .landing-page-wrapper {
    h1 {
      font-size: 1em;
      color: rgba(255,255,255,1);
      .bg {
        display: inline-block;
        background: rgba(0,0,0,0.9);
        padding: 0.3em 0.5em 0.4em 0.5em;
        border-radius: 0.2em;
      }
    }
    h2 {
      font-family: $deco;
      font-weight: 700;
      font-size: 2em;
      margin: 0;
      padding: 0.5em 0;

    }
    a {
      color: $pink;
    }
    .text {
      text-align: center;
      font-family: $signatureFont;
      background: transparentize(#1f00f6, 0.5);
      background: rgb(106,0,161);
      background: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);
      border-radius: 10px 10px 0 0;
      // background: url(https://afag.imgix.net/two-guys-from-italy-glendale/text-bg.png?auto=compress) repeat;
      padding-bottom: 1em;
      font-weight: 700;
      color: white;
      a {
        color: white;
      }
    }
    .sections-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .section {
      width: 33%;
      box-sizing: border-box;
      margin-bottom: 1%;
      text-align: center;
      &.banner {
        width: 100%;
        .content {
          border-radius: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .text {
          line-height: 1.3em;
          background: none;
          // text-align: left;
          padding: 0 1em 2em 1em;
        }
        .our-story {
          max-width: 600px;
          font-size: 0.8em;
          margin: 0 auto;
          line-height: 1.1em;
          strong {
            font-weight: 600;
          }
          p {
            margin: 0.8em 0;
          }
        }
      }
      .content {
        background-size: cover;
        background-position: center;
        height: 400px;
        border-radius: 10px;
      }

      @media screen and (max-width: 950px) {
        width: 99%;
      }
    }
  }
  .collage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .collage-element {
    // margin: 1em;
    width: 50%;
    img {
      max-width: 100%;
    }
  }
  .info {
    max-width: 100%;
    img {
      display: block;
    }
    .text-block {
      padding: 0.5em 0.5em;
      // background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1547498078/danstuff/background.png);
      font-family: $signatureFont;
    }
  }
  .slide {
    // border: 4px solid black;
    img {
      display: block;
    }
    // &:nth-of-type(2n) {
    //   transform: rotate(1deg)
    // }
    // &:nth-of-type(2n+1) {
    //   transform: rotate(-2deg)
    // }
  }

  a.order-online-button {
    // margin: 2em 0;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0.5em 0.5em;
    background: $pink;
    font-family: $signatureFont;
    font-weight: 600;
    box-shadow: none;
    margin-right: 0.5em;
    margin: 0.5em;
    margin-left: 0;
    border-radius: 15px;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523, 9px 9px #770523;
    transition: all 0.12s ease 0s;
    // position: absolute;
    // top: 0;
    // left: 0;
    &:hover {
      transform: translate(9px, 9px);
      box-shadow: none;
    }
    // border: 2px solid #222;
  }

  .order-benefits {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    background: url(https://images.unsplash.com/photo-1554502078-ef0fc409efce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60);
    background-size: cover;
    background-position: center 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order-benefits.home {
    background: url(https://images.unsplash.com/photo-1554502078-ef0fc409efce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60) no-repeat;
    background-size: cover;
    background-position: center 30%;
  }
  .order-benefits .text {
    background: rgba(255,255,255,0.8);
    padding: 0.5em 0.8em;
  }
  .order-benefits small {
    font-size: 0.8em;

  }
  .order-benefits h1 {
    font-weight: 900;
    font-size: 1.3em;
    /* background: white; */
  }
  .order-benefits p {
    /* background: white; */
  }


  .pages-nav {
    font-family: $deco;
    background: none;
    font-weight: 600;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    $borderWidth: 4px;
    $paddingY: 0.35em;
    text-decoration: none;
    color: $black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid rgba(100,100,100,0);
    &:hover, &.active {
      border-bottom: $borderWidth solid $pink;
    }
  }

  .page-content {
    max-width: 600px;
    margin: 1em auto;
    h1 {
      font-weight: 600;
      font-size: 1.5em;
      font-family: $deco;
    }
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $pink;
      color: $white;
    }
  }

  .footer {
    font-family: $signatureFont;
    margin-top: 2em;
    // border-top: 4px solid $pink;

    // background: $white;
    // color: black;
    a.phone-link {
      color: $pink;
    }
  }
}
