.about-page {
  // background: #191919;
  // color: white
  // font-family: 'Avenir Next';
  font-weight: 500;
  h1 {
    margin: 1em 0;
    // font-family: 'Avenir Next';
    font-weight: 600;
    font-size: 2em;
    text-align: center;
  }
  p {
    margin: 1.5em 0;
    clear: both;
    line-height: 1.3em;
  }
  .right, .left {

    width: 200px;
  }
  .right {
    margin-left: 15px;
    margin-bottom: 1em;
    margin-top: 10px;
    float: right;
  }
  .left {
    margin-right: 15px;
    margin-bottom: 1em;
    margin-top: 10px;
    float: left;
  }
  .post {
    max-width: 768px;
    margin: 0em auto;
    padding: 0 0.5em
  }
}

.page-links {
  display: none;
}
@media(min-width: 450px) {
  .page-links {
    display: block;
  }
}
a.nav-page-link {
  font-family: Fredoka One;
  font-weight: 600;
  margin-left: 1em;
  color: #e6001c;
  // text-decoration: none;
}
